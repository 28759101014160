export { default as IconBluesky } from './bluesky.svg';
export { default as IconFacebook } from './facebook.svg';
export { default as IconInstagram } from './instagram.svg';
export { default as IconLinkedin } from './linkedin.svg';
export { default as IconMastodon } from './mastodon.svg';
export { default as IconPinterest } from './pinterest.svg';
export { default as IconReddit } from './reddit.svg';
export { default as IconTelegram } from './telegram.svg';
export { default as IconTikTok } from './tiktok.svg';
export { default as IconTwitter } from './twitter.svg';
export { default as IconWhatsApp } from './whatsapp.svg';
export { default as IconYoutube } from './youtube.svg';
